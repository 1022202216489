import React from "react";
import SEO from "../../components/common/seo";
import LayoutThird from "../../components/common/layout-third";
import IdentityNav from "../../components/common/identity-nav";
import Partners from "../../components/common/partners";
import Interested from "../../components/identity/interested";
import ModalContactSales from "../../components/modals/modal-contact-sales";
import ContactBox from "../../components/common/contact-box";
import {graphql} from "gatsby";
import ModalWhitePaper from "../../components/modals/modal-white-paper";
import ReactMarkdown from "react-markdown";
import {customElements} from "../../utils/global-functions";
import rehypeRaw from "rehype-raw";

const LegalidadPage = ({data, pageContext}) => {
    const lang = pageContext.locale.path.default;
    const page = data.allStrapiIdentityLegalidad.edges[0].node;
    const customElementsTwo = (pClassName = '', ulClassName = '', liClassName = '', h3ClassName = '') => {
        return {
            p: ({node, ...props}) => <p className={pClassName} {...props} />,
            h3: ({node, ...props}) => <h3 className={h3ClassName} {...props} />,
            ul: ({node, ...props}) => <ul className={ulClassName} {...props} />,
            li: ({node, ...props}) => <li className={liClassName} {...props} />,
        }
    }

    return (
        <LayoutThird pageContext={pageContext} cookies={data.cookies} favicon="favicon-ide.png">
            <SEO lang={lang} title={page.seo.title} image={page.seo.image.localFile.publicURL} description={page.seo.meta_description}/>

            <IdentityNav pageContext={pageContext}/>

            <section id="product-top" className="content pt-13 pb-8 py-5 ahorro">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 mt-10 mb-3" data-aos="fade-right">
                            <h3 className="font-weight-bold spacing">
                                {page.title}
                            </h3>

                            <ReactMarkdown children={page.description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing mt-6 mb-2', 'default-link')}/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-5 mt-7" data-aos="fade-down">
                            <div className="position-absolute top-center top-md-left text-warning mt-n2 ml-md-n8 decor3">
                                <img src={require('../../img/products/ic_decoration.svg').default} alt=".."/>
                            </div>

                            <img src={require('../../img/products/do3.png').default} width="100%" className="img-fluid mt-2 mb-12 img-prod" alt="..."/>
                        </div>
                    </div>

                    <br/>
                </div>

                <br/>
            </section>

            <section className="position-relative py-8 py-md-11 mb-9 bg-static ahorro bg-white">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 col-lg-6">
                            <div className="device-combo device-combo-iphonex-iphonex mb-6 mb-md-0">
                                <div className="device device-iphonex" data-aos="fade-up">
                                    <img src={require("../../img/products/identity-mock-m6.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid ip" alt="..."/>
                                </div>

                                <div className="device device-iphonex" data-aos="fade-down">
                                    <img src={require("../../img/products/identity-mock-m7.png").default} className="device-screen" alt="..."/>
                                    <img src={require("../../img/products/iphonex.svg").default} className="img-fluid ip" alt="..."/>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 col-lg-5 offset-lg-1" data-aos="fade-up">
                            <h1 className="font-weight-bold spacing">
                                {page.validity_title}
                            </h1>

                            <ReactMarkdown children={page.validity_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg spacing mt-6 mb-4', 'default-link')}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="f3 mb-12">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-9 text-center">
                            <h4><span className="badge rounded-pill bg-tecalis mt-7 py-2 pr-3 pl-3 spacing text-white">{page.encryption_title}</span></h4>
                            <br/><br/>
                        </div>

                        <div className="col-12 col-md-10 col-lg-9" data-aos="fade-up">
                            <ReactMarkdown children={page.encryption_description} rehypePlugins={[rehypeRaw]} components={customElements('font-size-lg text-black-80 spacing', 'default-link')}/>
                        </div>

                        <div className="col-12 col-md-10 col-lg-9 mb-10" data-aos="fade-up">
                            <div align="center" className="img-benefits">
                                <img src={require('../../img/products/identity-lock.jpg').default} className="img-fluid" alt="..."/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-5 cta bg-grey border-bottom">
                <div className="container">
                    <div className="row mt-5 mb-10">
                        <div className="col-xl-4" data-aos="fade-up">
                            <img src={require('../../img/products/unlock.svg').default} width="450px" className="img-fluid mt-9 mb-5"/>
                        </div>

                        <div className="col-xl-1"/>

                        <div className="col-xl-7 check-list" data-aos="fade-down">
                            <h3 className="font-weight-bold spacing">
                                {page.privacity_title}
                            </h3>

                            <ReactMarkdown children={page.privacity_description}
                                           rehypePlugins={[rehypeRaw]}
                                           components={customElementsTwo('font-size-lg text-black-80 spacing mt-5 mb-2', 'spacing mb-5 font-size-sm')}/>
                        </div>
                    </div>
                </div>
            </section>

            <Interested pageContext={pageContext}/>

            <Partners pageContext={pageContext}/>

            <ContactBox pageContext={pageContext} product="Identity"/>

            <ModalContactSales pageContext={pageContext} product="Identity"/>

            <ModalWhitePaper pageContext={pageContext}/>
        </LayoutThird>
    )
}

export default LegalidadPage

export const legalidadPageQuery = graphql
    `query($lang: String) {
        allStrapiIdentityLegalidad ( filter: { locale: { eq: $lang } } )
        {
            edges {
                node {
                    id
                    seo {
                        title
                        meta_description
                        image {
                            localFile {
                                publicURL
                            }
                        }
                    }
                    title
                    description
                    validity_title
                    validity_description
                    encryption_title
                    encryption_description
                    privacity_title
                    privacity_description
                }
            }
        }
        cookies: markdownRemark(
            frontmatter: { 
                lang: {
                    eq: $lang 
                }
                name: {
                    eq: "cookies"
                }
            }) {
            frontmatter {
                cookiesStart
                cookiesEnd
                linkText        
            }
        }
}`;
